import { graphql } from "gatsby"
import React, { useState } from "react"
import { SRLWrapper } from "simple-react-lightbox"
import ImageDelay from "../components/ImageDelay"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./bildene.css"

const BildeDel = ({ tittel, bilder }) => {
  return (
    <section>
      <h2 className="my-2">{tittel}</h2>

      <div className="flex flex-row flex-wrap justify-center">
        {bilder.edges.map((edge) => {
          return (
            <div className="w-2/5 md:w-1/6 max-w-xs m-2">
              <ImageDelay fluid={edge.node.childImageSharp.fluid} />
            </div>
          )
        })}
      </div>
      <p className="my-2">📷 Alle bilder er tatt av Halvor Pritzlaff Njerve</p>
    </section>
  )
}

const activeClassName = "underline font-semibold"
const defaultClassName =
  "inline-block text-gray-900 hover:text-gray-900 hover:underline whitespace-no-wrap "

const BildeValg = ({ tekst, valgteBilder, setValgteBilder }) => (
  <li className="my-2 mx-2">
    <button
      className={
        defaultClassName + (valgteBilder === tekst ? activeClassName : "")
      }
      onClick={() => setValgteBilder(tekst)}
    >
      {tekst}
    </button>
  </li>
)

const Bildene = ({ data }) => {
  const [valgteBilder, setValgteBilder] = useState("Vielsen")
  return (
    <>
      <Layout>
        <div
          className="w-full block border-gray-600 border-t-2 border-b-2 pt-2 pb-2"
          id="nav-content"
        >
          <ul className="list-reset flex flex-wrap flex-1 items-center justify-center">
            <BildeValg
              tekst="Vielsen"
              valgteBilder={valgteBilder}
              setValgteBilder={setValgteBilder}
            />
            <BildeValg
              tekst="Oppstilte"
              valgteBilder={valgteBilder}
              setValgteBilder={setValgteBilder}
            />
            <BildeValg
              tekst="Familie"
              valgteBilder={valgteBilder}
              setValgteBilder={setValgteBilder}
            />
            <BildeValg
              tekst="Venner"
              valgteBilder={valgteBilder}
              setValgteBilder={setValgteBilder}
            />
            <BildeValg
              tekst="Middagen"
              valgteBilder={valgteBilder}
              setValgteBilder={setValgteBilder}
            />
            <BildeValg
              tekst="Fest"
              valgteBilder={valgteBilder}
              setValgteBilder={setValgteBilder}
            />
            <BildeValg
              tekst="Mat og kaker"
              valgteBilder={valgteBilder}
              setValgteBilder={setValgteBilder}
            />
            <BildeValg
              tekst="Detaljer og blinkskudd"
              valgteBilder={valgteBilder}
              setValgteBilder={setValgteBilder}
            />
          </ul>
        </div>
        <SRLWrapper>
          <SEO title="Brøllup" />
          {valgteBilder === "Vielsen" && (
            <BildeDel tittel="Vielsen" bilder={data.vielsen} />
          )}
          {valgteBilder === "Oppstilte" && (
            <BildeDel tittel="Oppstilte" bilder={data.oppstilte} />
          )}
          {valgteBilder === "Familie" && (
            <BildeDel tittel="Familie" bilder={data.familiebilder} />
          )}
          {valgteBilder === "Venner" && (
            <BildeDel tittel="Venner" bilder={data.venner} />
          )}
          {valgteBilder === "Middagen" && (
            <BildeDel tittel="Middagen" bilder={data.middagen} />
          )}
          {valgteBilder === "Fest" && (
            <BildeDel tittel="Fest" bilder={data.fest} />
          )}
          {valgteBilder === "Mat og kaker" && (
            <BildeDel tittel="Mat og kaker" bilder={data.matogkaker} />
          )}
          {valgteBilder === "Detaljer og blinkskudd" && (
            <BildeDel
              tittel="Detaljer og blinkskudd"
              bilder={data.forberedelser}
            />
          )}
        </SRLWrapper>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    vielsen: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/vielsen/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    familiebilder: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/familiebilder/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    fest: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/fest/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    matogkaker: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/matogkaker/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    venner: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/venner/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    forberedelser: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/forberedelser/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    middagen: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/middagen/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    oppstilte: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/oppstilte/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default Bildene
